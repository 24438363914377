import React, { Component, useEffect, useState } from "react"
import mianheaderimg from "../../assets/images/frontend-img/logo-new1.png";
import mainsliderheroimg from "../../assets/images/frontend-img/mainsliderheroimg.png";
import mainsliderelement from "../../assets/images/frontend-img/mainsliderelement.png";
import dropdownicon from "../../assets/images/frontend-img/dropdownicon.png";
import whowearetop from "../../assets/images/frontend-img/whowearetop.png";
import whoweareline from "../../assets/images/frontend-img/whoweareline.png";
import hdtw2 from "../../assets/images/frontend-img/hdtw2.png";
import icon1 from "../../assets/images/frontend-img/Mask Groupnew 11.png";
import icon2 from "../../assets/images/frontend-img/Mask Group 12.png";
import icon3 from "../../assets/images/frontend-img/Mask Group 13.png";
import icon4 from "../../assets/images/frontend-img/Mask Group 14.png";
import icon5 from "../../assets/images/frontend-img/Mask Group 15.png";
import icon6 from "../../assets/images/frontend-img/Mask Group 16.png";
import icon7 from "../../assets/images/frontend-img/Mask Group 17.png";
import shaphw1 from "../../assets/images/frontend-img/shaphw1.png";
import shaphw2 from "../../assets/images/frontend-img/shaphw2.png";
import shaphw3 from "../../assets/images/frontend-img/shaphw3.png";
import testimonialcheck from "../../assets/images/frontend-img/testimonialcheck.png";
import footershap from "../../assets/images/frontend-img/footershap.png";
import workflowsape from "../../assets/images/frontend-img/workflowsape.png";
import testimonialshap1 from "../../assets/images/frontend-img/testimonialshap1.png";
import testimonialshap from "../../assets/images/frontend-img/testimonialshap.png";
import axios from "axios";
import { useToasts } from 'react-toast-notifications';
import Slider from "react-slick";

const home = () => {
  const { addToast } = useToasts();
  const [TestimonialList, setTestimonialList] = useState([]);
  const [SliderList, setSliderList] = useState([]);
  const [UserMembershipList, setUserMembershipList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [QuickInquiryName, setQuickInquiryName] = useState('');
  const [QuickInquiryEmail, setQuickInquiryEmail] = useState('');
  const [QuickInquiryMobile, setQuickInquiryMobile] = useState('');
  const [QuickInquiryMessage, setQuickInquiryMessage] = useState('');
  const [NewsletterEmail, setNewsletterEmail] = useState('');
  const [CompanyEmail, setCompanyEmail] = useState('');
  const [CompanyEmailMailto, setCompanyEmailMailto] = useState('');
  const [CompanyContact, setCompanyContact] = useState('');
  const [CompanyContacttel, setCompanyContacttel] = useState('');
  const [facebookLink, setfacebookLink] = useState('');
  const [InstagramLink, setInstagramLink] = useState('');
  const [LinkedinLink, setLinkedinLink] = useState('');
  const [TwitterLink, setTwitterLink] = useState('');
  const [ServiceType, setServiceType] = useState([]); 
  const [ServiceFilter, setServiceFilter] = useState([]);
  const [ServiceList, setServiceList] = useState([]);
  const [ApproximatePriceMessage, setApproximatePriceMessage] = useState(0);

  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    arrows: false,
    beforeChange: function (currentSlide, nextSlide) {
    
    },
    afterChange: function (currentSlide) {
  
    }
  };

  const settings1 = {
    dots: true,
    infinite: true,

    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          autoplay: true,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          autoplay: true,
        }
      }
    ],

  };

  const settings2 = {
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ],

  };


  useEffect(() => {
    getTestimonialList();
    getMembershipList();
    getCompanyDetail();
    getCategoryList();
    getServiceType();
    getSliderList();
  }, []);

  const getServiceType = () => {
    setApproximatePriceMessage(0);
    var AUrl = global.APIURL + '/Servicetype';
    axios.get(AUrl)
      .then(res => {
        setServiceType(res.data.Result);
        for (let sl = 0; sl < res.data.Result.length; sl++) {
          const VServiceList = res.data.Result[sl].ServiceList;

          for (let sd = 0; sd < VServiceList.length; sd++) {
            const Ditem = {
              ServiceTypeId: res.data.Result[sl].ServiceTypeId,
              ServiceId: VServiceList[sd].ServiceId,
              ServiceName: VServiceList[sd].ServiceName,
              EstimatePrice: VServiceList[sd].EstimatePrice,
              Description: VServiceList[sd].Description,
              Media: VServiceList[sd].Media,
            };
            ServiceFilter.push(Ditem)
          }
        }
      });
  }

  const getServiceTypeChange = (STypeId) => {
    document.getElementById('txtApproximatePrice').value = 'Cost';
    var SF = ServiceFilter.filter(x => x.ServiceTypeId == STypeId);
    ServiceList.length == 0;
    setServiceList(SF);
  }

  const inputHandler = e => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  const getServiceChange = (SId) => {
    document.getElementById('txtApproximatePrice').value = 'Cost';
    var SF = ServiceFilter.filter(x => x.ServiceId == SId);
    if (SF.length > 0) {   
      var EstimatePrice = parseFloat(SF[0].EstimatePrice == null ? 0 : SF[0].EstimatePrice == '' ? 0 : SF[0].EstimatePrice);
      document.getElementById('txtApproximatePrice').value = "EstimatePrice";
      if (EstimatePrice == 0) {
        setApproximatePriceMessage(1);
      }
      else {
        setApproximatePriceMessage(0);
      }
    }
  }

  const getTestimonialList = () => {
    axios.post(global.APIURL + "/Testimonial")
      .then(res => {
        setTestimonialList(res.data.Result);
      })
  }

  const getSliderList = () => {
    axios.get(global.APIURL + "/Banner")
      .then(res => {
        setSliderList(res.data.Result);
      })
  }

  const getSubCategoryList = (CategoryId) => {
    const formData = new FormData();
    formData.append('CategoryId', CategoryId);
    axios.post(global.APIURL + '/SubCategoryList', formData)
      .then(res => {
        setSubCategoryList(res.data.Result);
      })
  }

  const getCategoryList = () => {
    axios.get(global.APIURL + "/CategoryGet")
      .then(res => {     
        setCategoryList(res.data.Result);
        const CategoryArr = res.data.Result;
        for (let ca = 0; ca < CategoryArr.length; ca++) {
          if (ca == 0) {
            const CategoryId = CategoryArr[ca].Id;
            getSubCategoryList(CategoryId);
          }
        }
      })
  }

  const getMembershipList = async () => {
    axios.get(global.APIURL + "/MembershipGet")
      .then(res => {     
        setUserMembershipList(res.data.Result);
      });
  };

  const getCompanyDetail = async () => {
    axios.get(global.APIURL + "/CompanyDetail")
      .then(res => {
        if(res.data.Result.length>0){
          setCompanyEmail(res.data.Result[0].CompanyEmail);
          setCompanyEmailMailto('mailto:' + res.data.Result[0].CompanyEmail);
          setCompanyContact(res.data.Result[0].CompanyContact);
          setCompanyContacttel('tel:' + res.data.Result[0].CompanyContact);
          setfacebookLink(res.data.Result[0].facebookLink);
          setInstagramLink(res.data.Result[0].InstagramLink);
          setLinkedinLink(res.data.Result[0].LinkedinLink);
          setTwitterLink(res.data.Result[0].TwitterLink);
        }
      
      });
  };

  const QuickInquirySave = (e) => {
    let IsRegister = 1;

    if (QuickInquiryName == '') {
      IsRegister = 0;
      addToast("Please Enter Name", { appearance: 'error', autoDismiss: true });
      return;

    }
    if (QuickInquiryEmail == '') {
      IsRegister = 0;
      addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });
      return;
    }
    if (regex.test(QuickInquiryEmail) === false) {
      IsRegister = 0;
      addToast("Please Enter Valid Email Address", { appearance: 'error', autoDismiss: true });
      return;

    }
    if (QuickInquiryMobile == '') {
      IsRegister = 0;
      addToast("Please Enter Mobile Number", { appearance: 'error', autoDismiss: true });
      return;

    }
    if (QuickInquiryMobile.length < 10) {
      IsRegister = 0;
      addToast("Please Enter Valid Mobile Number", { appearance: 'error', autoDismiss: true });
      return;

    }
    if (QuickInquiryMessage == '') {
      IsRegister = 0;
      addToast("Please Enter Message", { appearance: 'error', autoDismiss: true });
      return;

    }
    if (IsRegister > 0) {
      const formData = new FormData();
      formData.append('RegisterId', 1);
      formData.append('Name', QuickInquiryName);
      formData.append('Email', QuickInquiryEmail);
      formData.append('MobileNumber', QuickInquiryMobile);
      formData.append('Message', QuickInquiryMessage);    
      axios.post(global.APIURL + '/QuickInquiry', formData).then(res => {
        if (res.data.Status == true) {
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          document.getElementById('txtQuickInquiryName').value = '';
          document.getElementById('txtQuickInquiryEmail').value = '';
          document.getElementById('txtQuickInquiryMobile').value = '';
          document.getElementById('txtQuickInquiryMessage').value = '';
          setQuickInquiryName('');
          setQuickInquiryEmail('');
          setQuickInquiryMobile('');
          setQuickInquiryMessage('');
        }
        else {
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
        }
      });
    }
  };

  const NewsletterSave = (e) => {
    let IsRegister = 1;
    if (NewsletterEmail == '') {
      IsRegister = 0;
      addToast("Please Enter Email Address", { appearance: 'error', autoDismiss: true });
      return;
    }
    if (NewsletterEmail != "") {
      if (regex.test(NewsletterEmail) === false) {
        addToast("Please Enter Valid Email Address", { appearance: 'error', autoDismiss: true });
        return;
      }
    }
    if (IsRegister > 0) {
      const formData = new FormData();
      formData.append('RegisterId', 1);
      formData.append('Email', NewsletterEmail);

    

      axios.post(global.APIURL + '/Newsletter', formData).then(res => {
        if (res.data.Status == true) {
          addToast(res.data.StatusMessage, { appearance: 'success', autoDismiss: true });
          document.getElementById('txtNewsletterEmail').value = '';
          setNewsletterEmail('');
        }
        else {
          addToast(res.data.StatusMessage, { appearance: 'error', autoDismiss: true });
        }
      });
    }
  };

  const LoginRedirect = (e) => {
    window.location.href = "/login";
  };

  return (<React.Fragment>
    <div>
 
      <section className="maintopbar">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-white topbarsocialicon">
              <a href={facebookLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
              <a href={InstagramLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
              <a href={LinkedinLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
              <a href={TwitterLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
            </div>
            <div className="text-white topbarcontact d-flex d-md-block">
              <a className="mt-0" href={+CompanyContacttel}><i className="fas fa-phone-alt me-md-3 me-3 "></i> <span className="hidetexttopcall">Call us : {'+91-'+CompanyContact}</span></a>

              <a href={CompanyEmailMailto} className="me-0"><i className="fas fa-envelope me-md-3  me-0"></i> <span className="hidetexttopcall">Mail us : {CompanyEmail}</span> </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="">
          <div className="">
            <nav
              className="navbar navbar-expand-lg navbar-light hedermain nav-container p-0 withoutfixnav"
              aria-label="Eighth navbar example">
              <div className="container">
                <a className="navbar-brand" href="/home">
                  <img src={mianheaderimg} className="headerlogo" alt="" />
                </a>

                <div>
                  <button
                    className="navbar-toggler border-0 p-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarsExample07"
                    aria-controls="navbarsExample07"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="fas fa-bars menumobile"></i>
                  </button>
                </div>

                <div
                  className="collapse navbar-collapse navbar-collapseedit"
                  id="navbarsExample07"
                >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                    <li className="nav-item navbar-linksedit">
                      <a
                        className="nav-link "
                        aria-current="page"
                        href="/home"
                      >Home
                      </a>
                    </li>
                    <li className="nav-item navbar-linksedit">
                      <a className="nav-link scroll" href="#aboutusid">About us</a>
                    </li>
                    <li className="nav-item navbar-linksedit">
                      <a className="nav-link howitworkbutton" aria-current="page" href="#howitworkid"
                      >How it Works?</a
                      >
                    </li>
                    <li className="nav-item navbar-linksedit">
                      <a className="nav-link pricingidbutton" href="#pricingid">Pricing</a>
                    </li>

                    <li className="nav-item navbar-linksedit">
                      <a
                        className="nav-link"
                        aria-current="page"
                        href="/login"
                      >Login</a
                      >
                    </li>
                    <li className="nav-item navbar-linksedit">
                      <a
                        className="nav-link me-0 btneditgetstarted"
                        aria-current="page"
                        href="/register-your-profile"
                      >
                        <button type="button" className="btn btn-outline-light btngetstarted">Get Started</button></a>
                    </li>

                  </ul>
                </div>
              </div>
            </nav>


          </div>
        </div>
      </section>
      <section>
        <div className="maindivforhero">
          <div className="mainbgblue">
            {/* <img src={mainbg} className="mainbg" alt="" /> */}
          </div>

          <div className="container">
            <div className="row maindivforhero">
              <div className="col-md-6">
                <div className="maindivsideslider">
                  <div className="multiple-items newmainhomeslider">
                    <Slider {...settings1}>

                      {SliderList.map((item, idx) => (
                        <div key={idx}>
                          <img src={item.Image} className="mainbgsideimg" alt={item.Title} />
                        </div>
                      ))}
                    </Slider>
                    {/* <div>
                        <img src={mainsliderheroimg} className="mainbgsideimg" alt="" />
                      </div>
                      <div>
                        <img src={mainsliderheroimg} className="mainbgsideimg" alt="" />
                      </div> */}
                  </div>

                  <img src={mainsliderelement} className="mainbgsideimgelement" alt="" />

                </div>
              </div>
              <div className="col-md-6">
                <div className="maincontentherodiv">
                  <p className="mainherotext">
                    {/* Get the help you need, every
                      step of the way */}

            THE PROFESSIONAL PROPERTY MANAGEMENT SERVICE PROVIDER -SERVICE MALL
                  </p>
                  <p className="subtextedithero">


                    {/* Praesentium unde ipsum nihil hic non cupiditate distinctio dlorum tenetur. Explicabo et officia temporibus */}

                    Be with us and joy where ever you are
                  </p>
                  <div className="maindividercontent">
                  </div>
                  <p className="estimatecosttet">We are here to help you to maintain your property well. Let’s help you always and all ways. </p>
                  <div className="mainestimcostbox">
                    <div className="row">
                      <div className="col-md-4 pe-md-0 maindropdownbox">
                        <select className="form-select" aria-label="Default select example" onChange={e => getServiceTypeChange(e.target.value)}>
                          <option selected>Select Service Type</option>
                          {ServiceType.map((ServiceTypeItems) => (
                            <option key={ServiceTypeItems.ServiceTypeId} value={ServiceTypeItems.ServiceTypeId}> {ServiceTypeItems.ServiceTypeName} </option>
                          ))}
                        </select>
                        <img src={dropdownicon} className="dropdowniconedit" alt="" />
                      </div>
                      <div className="col-md-4 pe-md-0 ps-md-0 maindropdownbox">
                        <select className="form-select" aria-label="Default select example" onChange={e => getServiceChange(e.target.value)}>
                          <option selected>Select Service</option>
                          {ServiceList.map((ServiceItems) => (
                            <option key={ServiceItems.ServiceId} value={ServiceItems.ServiceId}> {ServiceItems.ServiceName} </option>
                          ))}
                        </select>
                        <img src={dropdownicon} className="dropdowniconedit" alt="" />
                      </div>
                      <div className="col-md-4 pe-md-0 ps-md-0 mainserchboxedit">
          {ApproximatePriceMessage == 1 ? <a href="/register-your-profile" className="btn btn-primary pricingblubtn registerbtncss">Please Book your AMC/package to know the estimate cost</a>:<input type="text" className="form-control" id="txtApproximatePrice" placeholder="Approximate Price" defaultValue={'Cost'} readOnly />
                     }
                     

                      </div>


                    </div>
                  </div>
                  {/* {ApproximatePriceMessage == 1 ?
                    <div className="mainaleartpricenew">
                      <p className=""><i className="fas fa-info-circle"></i>Please <a href="./register-your-profile">Book your AMC/package </a>to know the estimate cost.</p>

                    </div> : ''} */}
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row relaxationmain ">
            <div className="col-md-4">
              {/* <p className="maincommonsmalltext">
                Achieve More
              </p> */}
              <p className="commonmainbigtitle">
                RELAX WHERE EVER YOU ARE
              </p>
              <p className="blacktextedit">

                We are the first in India providing all services under a professional system by the support of technology. It’s a perfect solution  to manage , protect, track and execute the requirements easily by a mobile click.

              </p>

            </div>
            <div className="col-md-4 ">
              <div className="text-center mainboxrelaxtion mainboxrelaxtionnew">
                {/* <img src={builtforimpact} className="relaxationimgedit" alt="" /> */}
                <p className="rboxedittext">WE BUILT IT FOR FUTURE INDIA</p>
                <p className="mainrboxtextsub">
                You enjoy the quality, delivery and relax. We are here to furnish your requirements and request on time.

                </p>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="text-center mainboxrelaxtion2 mainboxrelaxtion2new">
                {/* <img src={goodtofindonline} className="relaxationimgedit" alt="" /> */}
                <p className="rboxedittext">CLICK & TRACK INSTEAD OF CALLS</p>
                <p className="mainrboxtextsub">

                  Our service is just a click away, and you may track how it’s working…

                </p>
              </div>
            </div>

          </div>
        </div>

      </section>
      <section id="aboutusid">
        <div className="whowearemaindiv">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7">

                <p className="maincommonsmalltext2">
                  Who We Are
                </p>

                <p className="commonmainbigtitle">
                  ABOUT COLAND SERVICES - SERVICE MALL
                </p>
                <p className="blacktextedit">
                  Coland Services Private Limited is LLC, professional property management company, introducing our new start-up ServiceMall is an ultimate service providing solution web app to our customers who are all away from the property, Non-Residential States, NRI’s who need to professional attention to the property and related services well.</p>
                <p className="blacktextedit">
                  We heading from Kerala and expanding the service operations all across India . Right now we provide our services across Kerala and some parts of Tamilnadu – Chennai & Coimbatore and Karnataka-Bangalore. Our aim is to provide the quality services to our valuable customers to meet their property management requirements by assuring quality, price and on time delivery.
                </p>
                <p className="blacktextedit">
                  We are here to act and work as a third eye of you. We always committed to deliver the best from our end, which we gained from the last 15 years from the industry experience and professional excellence.

                </p>
                <p className="blacktextedit">
                  We are your Property Protector, Inspector, Doctor, Nurse, Assistant, Engineer, Partner and Manger, etc and all other what do you expect from our side.
                </p>


                {/* <button type="button" className="btn btn-danger readmorebtn">Read More</button> */}

              </div>
              <div className="col-md-5">
                <div className="row align-items-center mainsidebarwwr">
                  <div className="col-md-3">
                  </div>
                  <div className="col-md-9">
                    <div className="mainwhowearebox">
                      <p className="wwrboxcount">01</p>
                      <p className="wwrboxtitle">VISION</p>
                      <p className="wwrboxsubtitle">To be the first choice of the customers  and ultimate property management solution in Property Management Industry.</p>

                    </div>
                    <div className="mainwhowearebox mainwhoweareboxnewedit">
                      <p className="wwrboxcount">02</p>
                      <p className="wwrboxtitle">MISSION</p>
                      <p className="wwrboxsubtitle">To be the Third Eye of our customers.
                        To become the quick provision and response of the customers to provide the quality delivery on time.
                        To provide satisfaction to our customers by the excellence of services.</p>


                    </div>

                  </div>


                </div>

              </div>

            </div>

          </div>
          <img src={whowearetop} className="whowearetopimg" alt="" />
          <img src={whoweareline} className="whowearelineimg" alt="" />
        </div>
      </section>
      <section id="howitworkid">
        <div className="container">
          <div className="mainsectiontitle">
            <div className="row">
              <div className="col-md-12 text-center">
                <p className="maincommonsmalltext">
               
                </p>
                <p className="commonmainbigtitle">
                Our WorkFlow
              
                </p>
              </div>
            </div>

            <div className="row mainboxesdivhowitwork">


              <div className="col-md-2 col-6">
                <div className="mainboxhowitwork">

                  <img src={icon1} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Become a member
                  </p>
                  <p className="mainrboxtextsub"></p>
                  <img src={shaphw1} className="shaphowit1" alt="" />
                </div>
              </div>
              <div className="col-md-2 col-6">
                <div className="mainboxhowitwork2">

                  <img src={icon2} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Add your properties
                  </p>
                  <img src={shaphw2} className="shaphowit2" alt="" />
                </div>
              </div>
              <div className="col-md-2 col-6">
                <div className="mainboxhowitwork">

                  <img src={icon3} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Select/Book services
                  </p>
                  <img src={shaphw3} className="shaphowit3" alt="" />
                </div>
              </div>
              <div className="col-md-2 col-6">
                <div className="mainboxhowitwork2">

                  <img src={icon4} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Get Quote
                  </p>
                  <img src={shaphw2} className="shaphowit2" alt="" />
                </div>

              </div>
              <div className="col-md-2 col-6 d-md-block d-none">
                <div className="mainboxhowitwork mt-2 mt-md-0">
                  <img src={icon5} className="howitworkboximg" alt="" />
                  {/* <img src={hdtw3} className="howitworkboximg" alt="" /> */}
                  <p className="howitboxtitle">
                    Approve Quote
                  </p>
                  <img src={shaphw3} className="shaphowit3" alt="" />
                </div>



              </div>
              <div className="col-md-2 col-6 d-md-none d-block ">
                <div className="mainboxhowitwork  mt-md-0">
                  <img src={hdtw2} className="howitworkboximg" alt="" />
                  {/* <img src={hdtw3} className="howitworkboximg" alt="" /> */}
                  <p className="howitboxtitle">
                    Approve Quote
                  </p>
                  <img src={shaphw3} className="shaphowit3" alt="" />
                </div>
              </div>
              
              <div className="col-md-2 col-6">
                <div className="mainboxhowitwork2">

                  <img src={icon6} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Track the progress
                  </p>
                  <img src={shaphw2} className="shaphowit2" alt="" />
                </div>

              </div>
              <div className="col-md-2 col-6 d-md-block d-none">
                <div className="mainboxhowitwork">

                  <img src={icon7} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Review
                  </p>
                </div>
              </div>
              
              <div className="col-md-2 col-6 d-md-none d-block mt-md-0">
                <div className="mainboxhowitwork">

                  <img src={icon6} className="howitworkboximg" alt="" />
                  <p className="howitboxtitle">
                    Review
                  </p>

                </div>



              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="maintestimonialdiv">
          <img className="leftshaptestimonial" src={testimonialshap1}></img>
          <img className="rightshaptestimonial" src={testimonialshap}></img>
          <div className="container">
            <div className="row ">
              <div className="col-md-4 maintestimonialleft">
                <p className="maincommonsmalltext">
                  Testimonial
                </p>
                <p className="commonmainbigtitlelight">
                  FEW OF OUR CUSTOMER COMMENTS
                </p>
                <p className="blacktexteditlight">
                  {/* Ut commodi et ipsam molestias omnis aliquid laudantium nemo dolore. Mollitia voluptas quod qui reprehenderit dolorem commodi repudiandae blanditiis.
               */} </p> 
                <div className="row maindetailleftsidetestimonial">
                  <div className="col-md-6">
                    <div className="maintestimonialdetils">
                      <p>
                        <img src={testimonialcheck} className="testimonialcheckimg" alt="" />
                        10+ Professional Expert
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="maintestimonialdetils">
                      <p>
                        <img src={testimonialcheck} className="testimonialcheckimg" alt="" />
                        100+ Services
                      </p>
                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="maintestimonialdetils">
                      <p>
                      <img src={testimonialcheck} className="testimonialcheckimg" alt="" />
                        {/* <img src={testimonialuser} className="testimonialuser" alt="" /> */}
                        100+ Users
                      </p>
                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="maintestimonialdetils">
                      <p>
                        <img src={testimonialcheck} className="testimonialcheckimg" alt="" />
                        20+ Customer Reviews
                      </p>
                    </div>

                  </div>

                </div>

                {/* <button type="button" className="btn btn-warning readmorebtnew">Read More</button> */}
              </div>
              <div className="col-md-8">
                <Slider {...settings}>
                 
                {TestimonialList.map((item, idx) => (
                      idx = idx % 2 == 0 ?
                        <div className="testimonialstarratingmain autoscrollli" key={idx}>
                          <div className="maintestitmonalboxrating">
                            <div className="d-flex align-items-center">
                              <div>
                                <img src={item.Image} className="testimonialuserimg" alt="" />
                              </div>
                              <div>
                                <p className="mainrboxtextsub newoneparagraph">&quot;{item.Message}&quot;</p>
                                <p className="testimonialname">{item.Name}</p>
                               
                              </div>

                            </div>

                          </div>

                        </div>
                        : idx = idx % 2 != 0 ?
                          <div className="testimonialstarratingmain autoscrollli" key={idx}>
                            <div className="maintestitmonalboxrating">
                              <div className="d-flex align-items-center">
                                <div>
                                  <img src={item.Image} className="testimonialuserimg" alt="" />
                                </div>
                                <div>
                                  <p className="mainrboxtextsub">&quot;{item.Message}&quot;</p>
                                  <p className="testimonialname">{item.Name}</p>
                                
                                </div>

                              </div>

                            </div>

                          </div>
                          : ''
                    ))}

                </Slider>
              
              </div>

            </div>


          </div>

        </div>
      </section>
      <section id="pricingid">
        <div className="container">
          <div className="bestdealboxmain">
            <div className="row">
              <div className="col-md-4">
                <div>
                  <p className="maincommonsmalltext">
                  
                  </p>
                  <p className="commonmainbigtitle">
                  OUR PACKAGES
                  </p>
                </div>
              </div>
              <div className="col-md-8">
                <div className="mainsidetextheading">
                  {/* <p className="mainrboxtextsub">
                    Est vitae optio excepturi voluptatibus consequatur pariatur deserunt. Praesentium blanditiis magnam error maiores sed. Est laborum doloribus nobis omnis cum ipsam officia.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="row miandivpricingbox">
              {UserMembershipList.map((item, idx) => (

                <div className="col-md-3" key={idx}>
                  <div className="mainpricetable">
                    <div className="boxpricing">
                      <p className="plantitle text-center">
                        {item.Name}
                      </p>
                      <p className="pricingtext">
                        ₹{parseFloat(item.Price == null ? '0' : item.Price == '' ? '0' : item.Price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}<span>/{item.ValidUpTo.replace(/[0-9]/g, '')}</span>
                      </p>
                      <div className="dividerpricing"></div>

                      <div className="contentpricing">
                        <div className="maincontenteditbox" dangerouslySetInnerHTML={{ __html: item.Description }}></div>
                       
                        <a href="/register-your-profile" className="btn btn-primary pricingblubtn">Get Started</a>
                      </div>
                    </div>
                  </div>

                </div>
              ))}
            </div>
          </div>

        </div>
      </section>
      <section>
        <div className="container">
          <div className="mainworkflowtext">
            <img className="workflowshapimg" src={workflowsape}></img>
            <div className="text-center">
              <p className="maincommonsmalltext2"></p>
              <p className="commonmainbigtitle">  OUR PROFESSIONAL SERVICES ARE..</p>
            </div>
            <div className="maintabshowwework">
              <ul className="nav nav-pills justify-content-center" id="pills-tab" role="tablist">
                {CategoryList.map((item, idx) => (
                  <li className="nav-item" role="presentation" key={idx}>
                    {idx == 0 ?
                      <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={e => getSubCategoryList(item.Id)}>{item.Name}</button>
                      :
                      <button className="nav-link" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={e => getSubCategoryList(item.Id)}>{item.Name}</button>
                    }
                  </li>
                ))}
                {/* <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">General Services</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Estate / Project  Management</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-contact-tab4" data-bs-toggle="pill" data-bs-target="#pills-contact4" type="button" role="tab" aria-controls="pills-contact4" aria-selected="false">Documentation</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-contact-tab5" data-bs-toggle="pill" data-bs-target="#pills-contact5" type="button" role="tab" aria-controls="pills-contact5" aria-selected="false">Other Services</button>
                  </li> */}
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div className=" workflowslider newsliderwork">
                    <Slider {...settings2}>
                      {SubCategoryList.map((item, idx) => (
                        <div key={idx}>
                          <div className="workflowbox">
                            <img src={item.Image} alt="" />
                            <p className="workflowboxtitle">{item.Title}</p>
                            {/* <p className="workflowboxdetailks">{item.Description}</p> */}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center helpdiv">
            <div className="text-center col-md-6">
              <p className="commonmainbigtitle">We Are Here To Help You!</p>
              {/* <p className="mainrboxtextsub"></p> */}
              <a href="/register-your-profile" className="btn btn-warning readmorebtnew">Get Started</a>
            </div>

          </div>

        </div>
      </section>
      <section id="contactusid">
        <div className="footermaindiv">
          <img src={footershap} className="footershapimg"></img>
          <img src={footershap} className="footershapimg"></img>

          <div className="container">
            <div className="row maincontentdivfooter">
              <div className="col-md-4">
                <div className="paddingfooterdiv">

                  {/* <p className="footertitle"> Company Address</p> */}

                  <p className="footerdescription mt-1">   </p>
                  <p className="footerdescription">
                   <h5 className="footertitle"> CoLand Services Private Limited</h5>
                    
                    <p className="footerdescription"> 1st Floor,</p>
                    Bhagyalakshmi Indoor Stadium,
                  </p>
                  <p className="footerdescription">
                    Thrissur -Shornur road,
                  </p>
                  <p className="footerdescription">
                    Kolazhy, Thrissur,
                  </p>
                  <p className="footerdescription">
                    Kerala, India-680010
                  </p><br/>
                  <p className="footertitle addrescss"> Contact Us On:</p>
                  <p className="footerdescription mt-1 "><i className="fa-solid fa-phone me-2"></i>+91-9947422444 </p>
                  <p className="footerdescription mt-1"><i className="fa-solid fa-phone me-2"></i>+91-9847982444 </p>
                  <p className="footerdescription mt-1"><i className="fa-solid fa-phone me-2"></i>+91-487- 299 6244</p>

                  <br/>
                  <p className="footerdescription mt-1"><p className="footertitle addrescss">Our Branches : </p> Ernakulam | Kottayam | Thiruvalla | Trivandrum | Palakkad | Malappuram | Calicut | Chennai | Bangalore</p>
               
                  
              
                  <a onClick={e => LoginRedirect(e)} className="btn btn-danger loginbtnfooter">Login</a>

                </div>

              </div>
              <div className="col-md-4">
                <div className="paddingfooterdiv2">
                  <p className="footertitle"> Quick Inquiry</p>
                  <div className="footerform">
                    <input type="email" className="form-control" id="txtQuickInquiryName" placeholder="Name" onChange={(e) => setQuickInquiryName(e.target.value)} />
                  </div>
                  <div className="footerform">
                    <input type="email" className="form-control" id="txtQuickInquiryEmail" placeholder="Email Address" onChange={(e) => setQuickInquiryEmail(e.target.value)} />
                  </div>
                  <div className="footerform">
                    <input type="number" className="form-control" maxLength={10} id="txtQuickInquiryMobile" placeholder="Mobile Number" onChange={(e) => setQuickInquiryMobile(e.target.value)} onKeyPress={inputHandler} />
                  </div>
                  <div className="footerform">
                    <input type="email" className="form-control" id="txtQuickInquiryMessage" placeholder="Message" onChange={(e) => setQuickInquiryMessage(e.target.value)} />
                  </div>
                  <div>
                    <a onClick={e => QuickInquirySave(e)} className="submittextbtn">Submit<i className="fas fa-long-arrow-alt-right"></i></a>
                  </div>

                </div>

              </div>
              <div className="col-md-4">
                <div>
                  <p className="footertitle">Newsletter</p>
                  {/* <p className="footerdescription">Omnis illo ipsum pariatur magni unde necessitatibus ut illo. In autem qui ut accusamus sint perferendis veritatis voluptatem. Eos impedit ea. </p>*/}
                  <div className="input-group input-groupfooter">
                    <input type="text" id="txtNewsletterEmail" className="form-control" placeholder="Email Address" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setNewsletterEmail(e.target.value)} />
                    <button className="btn btn-danger" type="button" id="button-addon2" onClick={e => NewsletterSave(e)}>Submit</button>
                  </div>


                </div>

              </div>

            </div>
            <div className="footersocialmedia">
              <div className="row">
                <div className="col-md-8">
                  <div className="mainfooterpagetext d-md-flex d-block">
                    <p className="footerpagetext d-md-block d-none"> <a href="/terms-of-user">Terms & Conditions </a> </p>
                    <p className="footerpagetext2 d-flex d-md-none" > <a href="/terms-of-user">Terms & Conditions </a> </p>
                    <p className="footerpagetext2"> <a href="/privacy">Privacy Policy</a></p>
                    <p className="footerpagetext2"> <a href="/refund">Refund Policy</a></p>
                    <p className="footerpagetext2"> <a href="/cookie-policy">Cookie Policy</a></p>
                    <p className="footerpagetext2"> <a href="/EULA">EULA</a></p>
                    <p className="footerpagetext2"> <a href="/disclaimer">Disclaimer</a></p>
                    <p className="footerpagetext2"> <a href="/accept-use-policy">Acceptable Use Policy</a></p>
                  </div>


                </div>
                <div className="col-md-4">
                  <div className="footersocialmediaicon">
                    <a href={facebookLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f ms-0"></i></a>
                    <a href={InstagramLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                    <a href={LinkedinLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                    <a href={TwitterLink} target="_blank" rel="noopener noreferrer"> <i className="fab fa-youtube"></i></a>
                   
                  </div>
                </div>

              </div>

            </div>
            <div className="disclamerlinefooter">
              <p>
                COPYRIGHT © ALL RIGHTS RESERVED BY CoLand Services Private Limited
              </p>

            </div>

          </div>

        </div>
      </section>
   
    </div>
  
  </React.Fragment>
  )

}

export default home
